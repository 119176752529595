import React from "react";

import {
  Navbar,
  Introduction as IntroductionComponent,
  Blog,
  Footer,
  SEO
} from "../components";

const Introduction = () => {
  return (
    <div>
      <SEO title="Merkl Kinga - Bemutatkozás" description="Merkl Kinga - Gödöllőn és környékén családok, gyermekek, csoportok, személyek fotózása." />
      <Navbar />
      <IntroductionComponent />
      <Footer />
    </div>
  );
};

export default Introduction;
